import { AfterViewInit, Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { IdleService } from './shared/services/idle.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit,  AfterViewInit, OnDestroy {

  private idleTimeout: any;
  showMenu = true;
  constructor(@Inject(Injector) private injector: Injector, private primengConfig: PrimeNGConfig, private router: Router, private idleMonitorService: IdleService, private oidcAuthService: AuthService) {}


  ngAfterViewInit() {
    this.primengConfig.ripple = true;

    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        if(event.url === '/unauthorized' || event.urlAfterRedirects.length <= 1) {
          this.showMenu = false;
        }
      }
    });
    this.primengConfig.setTranslation({
      accept: 'Aceitar',
      reject: 'cancelar',
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        today: 'Hoje',
        clear: 'Limpar',
  });
  }

  ngOnInit(): void {

    // Monitorando quando o usuário se torna inativo
    this.idleMonitorService.idle$.subscribe(() => {
     
       // Exibir uma mensagem de aviso
       this._toastrService.error('Sua sessão expirou, inativo por 05 minutos. Faça o Login novamente!');
       this.oidcAuthService.logout();
       this.router.navigate(['/login'])
    });

    // Monitorando quando o usuário interage novamente
    this.idleMonitorService.activity$.subscribe(() => {
     this.idleMonitorService.resetIdleTimer(); // Reset no monitoramento
    });
  }

  private get _toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  ngOnDestroy(): void {
    this.idleMonitorService.stop(); // Parar o monitoramento ao destruir o componente
  }
}
