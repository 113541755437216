import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleService {

  private timeoutDuration: number = 300000; // 10 minutos em milissegundos
  private idleTime: number = 0;
  private idleTimer: any;
  private events = ['mousemove', 'keydown', 'scroll', 'click'];
  private activitySubject = new Subject<void>();
  private idleSubject = new Subject<void>();

  // Emitidos quando o usuário se torna inativo ou volta a interagir
  activity$ = this.activitySubject.asObservable();
  idle$ = this.idleSubject.asObservable();

  constructor(private ngZone: NgZone) {
    // Subscrição dos eventos de interação do usuário
    this.events.forEach((event) => {
      this.ngZone.runOutsideAngular(() => {
        window.addEventListener(event, this.resetIdleTimer.bind(this));
      });
    });
    
    // Inicia o monitoramento de inatividade
    this.startIdleTimer();
  }

  private startIdleTimer() {
    this.idleTimer = setInterval(() => {
      this.idleTime += 1000;
      if (this.idleTime >= this.timeoutDuration) {
        this.idleSubject.next(); // Emite que o usuário está inativo
      }
    }, 1000); // Verifica a inatividade a cada segundo
  }

   resetIdleTimer() {
    this.idleTime = 0;
    this.activitySubject.next(); // Emite que o usuário voltou a interagir
  }

  // Método para parar o monitoramento de inatividade (se necessário)
  stop() {
    clearInterval(this.idleTimer);
  }
}
